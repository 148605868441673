@use '../../styles/variables' as *;

.education {
  margin-bottom: 2rem;

  h2 {
    font-size: 1.5rem;
    color: $black;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 0.5rem 0;
      color: $gray;
    }
  }
}
