@use '../../styles/variables' as *;

.about {
  margin-bottom: 2rem;

  h2 {
    font-size: 1.5rem;
    color: $black;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    color: $gray;
    line-height: 1.6;
  }
}
