@use '../../styles/variables' as *;

.work-experience {
  margin-bottom: 2rem;

  h2 {
    font-size: 1.5rem;
    color: $black;
    margin-bottom: 0.5rem;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      font-size: 1rem;
      color: $gray;
      line-height: 1.6;

      a {
        color: $primary-color;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
