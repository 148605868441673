@use '../styles/variables' as *;

.app {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Roboto Mono', monospace;
  line-height: 1.6;

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0.5rem 0;
    color: $black;
  }

  p {
    margin: 0.5rem 0;
    color: $gray;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 0.5rem 0;
    }
  }
}
