@use '../../styles/variables' as *;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  .header-content {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .text {
    h1 {
      font-size: 2rem;
      color: $black;
      margin: 0;
    }

    p {
      font-size: 1.2rem;
      color: $gray;
    }
  }

  .image {
    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
}
