@use '../../styles/variables' as *;

.contact {
  text-align: left; // Align container to the left
  margin-bottom: 2rem;
  display: flex; // Use flexbox to arrange links horizontally
  gap: 1.5rem; // Space between the links

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
